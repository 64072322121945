async function convertHeic(file) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (!/\.hei[cf]$/i.test(file.name) || isSafari) {
    return file;
  }
  try {
    const { default: heic2any } = await import('heic2any');
    const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
    const { type } = blob;
    const extension = type.split('/').pop();
    const filename = file.name.replace(/\.\w+/, `.${extension}`);
    const result = new File([blob], filename, { type });
    return result;
  } catch (error) {
    console.error(error);
    return file;
  }
}

export default convertHeic;
