/**
*
* FormikControl
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { useField } from 'formik';
import { useIsMounted } from 'utils/useSafeState.js';
import cx from 'utils/cx.js';
import FormControl from './FormControl.jsx';
import getControlValue from './getControlValue.js';
import InnerWrapper from './InnerWrapper.jsx';
import getValue from './getValue.js';

function asString(value, itemToString) {
  if (itemToString) {
    return itemToString(value);
  }
  return value?.toString();
}

function FormikControl(props) {
  const { field, helpers, options, onChange } = props;
  const { id, as, onEdit, onSubmit, itemToString, addText, disabled } = field;
  const isMounted = useIsMounted();
  const { setFieldValue, setFieldTouched, values, touched, errors, submitCount } = helpers;
  const value = values[id];

  function handleBlurDelayed() {
    // ensure onCancel is handled before onBlur
    window.setTimeout(() => {
      if (isMounted()) {
        setFieldTouched(id, true);
      }
    }, 300);
  }

  function handleChange(val) {
    setFieldValue(id, val);
    if (onChange) {
      onChange(id, val);
    }
  }

  function handleEdit() {
    setFieldTouched(id, false);
    onEdit(id, value);
  }

  const controlProps = {
    ...pick(field, ['type', 'as', 'placeholder', 'disabled', 'readOnly', 'required']),
    ...field.attributes,
    ...options?.attributes,
    name: id,
    value: getValue(value, field),
    onBlur: handleBlurDelayed,
    onChange: (v) => handleChange(getControlValue(v)),
    isInvalid: (submitCount > 0 || touched[id]) && !!errors[id],
  };

  const [, meta] = useField(controlProps);
  const canEdit = !controlProps.disabled && !controlProps.readOnly;
  const viewClass = cx('lh-lg me-2', !canEdit && 'opacity-50');
  const controlClass = cx(controlProps.className, as && meta.error && meta.touched && 'is-invalid');
  return addText && onEdit
    ? <Button variant="link" className="p-0" disabled={disabled} onClick={handleEdit}><FormattedMessage {...addText} /></Button>
    : (
      <InnerWrapper {...props} showButtons={canEdit}>
        {onEdit
          ? <div className={viewClass}>{asString(value, itemToString) || <span className="input-placeholder">{field.placeholder}</span>}</div>
          : <FormControl autoFocus={Boolean(onSubmit)} {...controlProps} className={controlClass} />}
      </InnerWrapper>
    );
}

FormikControl.propTypes = {
  field: PropTypes.object,
  helpers: PropTypes.object,
  options: PropTypes.object,
  onChange: PropTypes.func,
};

export default FormikControl;
