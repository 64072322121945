/*
 * AdminUsers Messages
 *
 * This contains all the text for the AdminUsers component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  users: {
    id: 'app.containers.Dashboard.users',
    defaultMessage: 'Users',
  },
  noUsers: {
    id: 'app.components.AdminUsers.noUsers',
    defaultMessage: 'No users',
  },
  noRoles: {
    id: 'app.components.AdminUsers.noRoles',
    defaultMessage: 'No roles',
  },
  employee: {
    id: 'app.components.UsersByRole.employee',
    defaultMessage: 'Employees',
  },
  parent: {
    id: 'app.components.UsersByRole.parent',
    defaultMessage: 'Parents',
  },
  student: {
    id: 'app.components.UsersByRole.student',
    defaultMessage: 'Students',
  },
  staff: {
    id: 'app.components.UsersByRole.staff',
    defaultMessage: 'Staff Members',
  },
  role: {
    id: 'app.components.UsersByRole.role',
    defaultMessage: 'Roles',
  },
  noMembers: {
    id: 'app.components.UsersByRole.noMembers',
    defaultMessage: 'No members',
  },
  name: {
    id: 'app.common.name',
    defaultMessage: 'Name',
  },
  add: {
    id: 'app.common.add',
    defaultMessage: 'Add',
  },
  selectGroups: {
    id: 'app.components.AdminUsers.selectGroups',
    defaultMessage: 'Select Groups',
  },
  active: {
    id: 'app.components.AdminUsers.account',
    defaultMessage: 'Account',
  },
  user: {
    id: 'app.containers.Dashboard.users',
    defaultMessage: 'Users',
  },
  sharedInbox: {
    id: 'app.components.AdminUsers.sharedInbox',
    defaultMessage: 'Shared Inbox',
  },
  numParents: {
    id: 'app.components.AdminUsers.numParents',
    defaultMessage: '{count, plural, =0 {No parents} one {1 parent} other {# parents}}',
  },
  resetPassword: {
    id: 'app.components.AdminUsers.resetPassword',
    defaultMessage: 'Reset Password',
  },
  generatePasswordResetLink: {
    id: 'app.components.AdminUsers.resetPassword.generatePasswordResetLink',
    defaultMessage: 'Generate password reset link',
  },
  passwordResetLink: {
    id: 'app.components.AdminUsers.resetPassword.passwordResetLink',
    defaultMessage: 'Password Reset Link',
  },
  copyResetLink: {
    id: 'app.components.AdminUsers.resetPassword.copyResetLink',
    defaultMessage: 'Copy the link below and send to the parent or ask the parent to scan the QR code below',
  },
  removeUser: {
    id: 'app.components.AdminUsers.removeUser',
    defaultMessage: 'Remove User',
  },
  removeRole: {
    id: 'app.components.AdminUsers.removeRole',
    defaultMessage: 'Remove Role',
  },
  removeFrom: {
    id: 'app.components.AdminUsers.removeFrom',
    defaultMessage: 'Remove',
  },
  userDeleted: {
    id: 'app.components.AdminUsers.userDeleted',
    defaultMessage: 'The user has been deleted',
  },
  roleDeleted: {
    id: 'app.components.AdminUsers.roleDeleted',
    defaultMessage: 'The role has been deleted',
  },
  confirmRemoveUser: {
    id: 'app.components.AdminUsers.confirmRemoveUser',
    defaultMessage: "Remove user ''{accountName}''?",
  },
  confirmRemoveRole: {
    id: 'app.components.AdminUsers.confirmRemoveRole',
    defaultMessage: "Remove role ''{displayName}''?",
  },
  disconnect: {
    id: 'app.components.AdminUsers.disconnect',
    defaultMessage: 'Disconnect',
  },
  confirmDisconnect: {
    id: 'app.components.AdminUsers.confirmDisconnect',
    defaultMessage: "Disconnect ''{name}''?",
  },
  confirmRemoveFromGroup: {
    id: 'app.components.AdminUsers.confirmRemoveFromGroup',
    defaultMessage: "Remove from group ''{name}''?",
  },
  addToGroup: {
    id: 'app.components.AdminUsers.addToGroup',
    defaultMessage: 'Add {count, plural, =0 {} other {#}} to Group',
  },
  addStudents: {
    id: 'app.components.AdminUsers.addStudents',
    defaultMessage: 'Add Students',
  },
  generalInfo: {
    id: 'app.components.AdminUsers.generalInfo',
    defaultMessage: 'General Info',
  },
  alias: {
    id: 'app.components.EditProfile.aliasLabel',
    defaultMessage: 'Alias',
  },
  connectionCodeEmployee: {
    id: 'app.components.AdminUsers.connectionCodeEmployee',
    defaultMessage: 'Connection Code Employee',
  },
  contactDetails: {
    id: 'app.components.UserModal.contactDetails',
    defaultMessage: 'Contact Details',
  },
  email: {
    id: 'app.components.EditProfile.email',
    defaultMessage: 'Email address',
  },
  address: {
    id: 'app.components.EditProfile.address',
    defaultMessage: 'Address',
  },
  phoneNumber: {
    id: 'app.components.EditProfile.phoneNumber',
    defaultMessage: 'Phone',
  },
  birthDate: {
    id: 'app.components.AdminUsers.birthDate',
    defaultMessage: 'Date of Birth',
  },
  yearClass: {
    id: 'app.components.LibraryGoalEditor.gradeLabel',
    defaultMessage: 'Grade',
  },
  account: {
    id: 'app.components.AdminGroups.account',
    defaultMessage: 'Account',
  },
  username: {
    id: 'app.components.EditAccount.username',
    defaultMessage: 'Username',
  },
  printLetter: {
    id: 'app.components.AdminUsers.printLetter',
    defaultMessage: 'Print Activation Letter',
  },
  children: {
    id: 'app.components.UserModal.children',
    defaultMessage: 'Children',
  },
  parents: {
    id: 'app.components.UserModal.parents',
    defaultMessage: 'Parents',
  },
  groups: {
    id: 'app.components.AdminGroups.groups',
    defaultMessage: 'Groups',
  },
  schoolLabel: {
    id: 'app.components.AdminGroups.school',
    defaultMessage: 'School',
  },
  classesLabel: {
    id: 'app.components.AdminGroups.class',
    defaultMessage: 'Class',
  },
  generalLabel: {
    id: 'app.components.AdminGroups.generalLabel',
    defaultMessage: 'General',
  },
  foundationLabel: {
    id: 'app.components.AdminGroups.foundation',
    defaultMessage: 'Foundation',
  },
  export: {
    id: 'app.common.export',
    defaultMessage: 'Export',
  },
  adminGroups: {
    id: 'app.components.AdminGroups.adminGroups',
    defaultMessage: 'Administrative Groups',
  },
  gradeFull: {
    id: 'app.components.AdminUsers.gradeFull',
    defaultMessage: 'Grade {yearClass}',
  },
  gradeShort: {
    id: 'app.components.AdminUsers.gradeShort',
    defaultMessage: 'Gr. {yearClass}',
  },
  inviteParents: {
    id: 'app.components.AdminUsers.inviteParents',
    defaultMessage: 'Invite Parents',
  },
  invite: {
    id: 'app.components.AdminUsers.invite',
    defaultMessage: 'Invite',
  },
  inviteParentIntro: {
    id: 'app.components.AdminUsers.inviteParentIntro',
    defaultMessage: 'Invite a parent/guardian for {firstName} to use Social Schools.',
  },
  inviteEmployeeTitle: {
    id: 'app.components.AdminUsers.inviteEmployee',
    defaultMessage: 'Invite {name}',
  },
  inviteEmployeeIntro: {
    id: 'app.components.AdminUsers.inviteIntro',
    defaultMessage: 'Invite {name} to use Social Schools as employee',
  },
  connectionCodeForThis: {
    id: 'app.components.AdminUsers.connectionCodeForThis',
    defaultMessage: 'The connection code for this is <samp>{code}</samp>',
  },
  invitePrintHelp: {
    id: 'app.components.AdminUsers.invitePrintHelp',
    defaultMessage: 'Open a web page that you can print and give to the {roleName, select, employee {employee } student {student} other {??}}.',
  },
  invitePrintButton: {
    id: 'app.components.AdminUsers.invitePrintButton',
    defaultMessage: 'Open Page',
  },
  firstName: {
    id: 'app.components.Name.firstName',
    defaultMessage: 'First name',
  },
  middleName: {
    id: 'app.components.AdminGroups.middleName',
    defaultMessage: 'Affix',
  },
  lastName: {
    id: 'app.components.Name.lastName',
    defaultMessage: 'Last name',
  },
  child_ren: {
    id: 'app.components.AdminUsers.child_ren',
    defaultMessage: 'Child(ren)',
  },
  updateField: {
    id: 'app.components.AdminUsers.updateField',
    defaultMessage: 'Update {field}',
  },
  noActiveAccount: {
    id: 'app.components.AdminUsers.noActiveAccount',
    defaultMessage: 'Not an active account',
  },
  passwordChanged: {
    id: 'app.components.EditAccount.passwordChanged',
    defaultMessage: 'The password has been changed',
  },
  select: {
    id: 'app.dropdown.select',
    defaultMessage: 'Select…',
  },
  none: {
    id: 'app.common.none',
    defaultMessage: 'None',
  },
  more: {
    id: 'app.components.AdminUsers.more',
    defaultMessage: 'More',
  },
  administration: {
    id: 'app.components.AdminUsers.administration',
    defaultMessage: 'Administration',
  },
  administrationLink: {
    id: 'app.components.AdminUsers.administrationLink ',
    defaultMessage: 'To Student Page',
  },
  administrationInfo: {
    id: 'app.components.AdminUsers.administrationInfo',
    defaultMessage: 'View absences, leave requests, consents and other administrative records',
  },
  addRole: {
    id: 'app.components.AdminUsers.addRole',
    defaultMessage: 'Add Role',
  },
  duplicateRoleName: {
    id: 'app.components.AdminUsers.duplicateRoleName',
    defaultMessage: 'This role name exists already',
  },
  confirmRemoveMember: {
    id: 'app.components.AdminGroups.confirmRemoveMember',
    defaultMessage: 'Remove member {accountName}?',
  },
  adduser: {
    id: 'app.components.Button.adduser',
    defaultMessage: 'Add User',
  },
  conversations: {
    id: 'app.components.Conversations.conversations',
    defaultMessage: 'Conversations',
  },
  sharedInboxItems: {
    id: 'app.components.AdminUsers.sharedInboxItems',
    defaultMessage: '<ul><li>Users with the same role have a shared inbox which they manage together</li><li>From this inbox, they can start a conversation with all parents and employes in the school</li><li>All parents and employes in the school can choose this inbox when they start a conversation</li></ul>',
  },
  activate: {
    id: 'app.components.AdminUsers.activate',
    defaultMessage: 'Activate',
  },
  doArchive: {
    id: 'app.components.AdminUsers.doArchive',
    defaultMessage: 'Archive',
  },
  archived: {
    id: 'app.components.AdminUsers.archived',
    defaultMessage: 'Archived',
  },
  reactivate: {
    id: 'app.components.AdminUsers.reactivate',
    defaultMessage: 'Reactivate',
  },
  standardRole: {
    id: 'app.components.AdminUsers.standardRole',
    defaultMessage: 'Standard role',
  },
  ssoConfigured: {
    id: 'app.components.AdminUsers.ssoConfigured',
    defaultMessage: 'SSO configured',
  },
  ssoInfo: {
    id: 'app.components.AdminUsers.ssoInfo',
    defaultMessage: 'Users can log in to Social Schools via SSO by connecting external account(s)',
  },
  socsLoginDisabled: {
    id: 'app.components.AdminUsers.socsLoginDisabled',
    defaultMessage: 'Social Schools Login Disabled',
  },
  onlyExternalInfo: {
    id: 'app.components.AdminUsers.onlyExternalInfo',
    defaultMessage: 'Users that have Social Schools login disabled can only log in via SSO with their connected external accounts',
  },
  disabledSocsLogin: {
    id: 'app.components.AdminUsers.disabledSocsLogin',
    defaultMessage: 'This user has disabled logging in with Social Schools username and password. Re-enable it to reset the password for this user.',
  },
  logInSocs: {
    id: 'app.components.EditAccount.logInSocs',
    defaultMessage: 'Log in with Social Schools username and password',
  },
  onlySelfDisableLogin: {
    id: 'app.components.AdminUsers.onlySelfDisableLogin',
    defaultMessage: 'Only the user himself can disable logging in with Social Schools username and password',
  },
});
