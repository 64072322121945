import { createGlobalStyle } from 'styled-components';

import 'animate.css';
import 'styles/animation.css';
import { inputMixin } from 'components/tags/Input/index.jsx';
import reactSelectMixin from 'components/interaction/ReactSelect/reactSelectMixin.js';
import componentsMixin from './mixins/index.js';
import iconStyles from './iconStyles.js';

import './bootstrap/custom.scss';
import './dirStyles.css';

// Icomoon icon fonts
import './icomoon/style.css';
// Enables outline if tab-key used
import '../utils/tabListener.js';
// General styles and styles for bootstrap components
import iosZoom from './ioszoom.js';
import placeholderMixin from './placeholderMixin.js';

import './tailwind.css';

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  ${componentsMixin}
  ${reactSelectMixin}
  ${iosZoom};

  html {
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    cursor: inherit;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  }

  body {
    height: 100%;
    width: 100%;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
  }

  body:not(.user-is-tabbing) *:focus {
    outline: none !important;
  }

  body.user-is-tabbing *:focus {
    /* From https://ghinda.net/article/mimic-native-focus-css */
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;

    /* WebKit gets its native focus styles. */
    @media (-webkit-min-device-pixel-ratio:0) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }
    
  body.noScroll { /* ...or body.dialogShowing */
    overflow: hidden;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    @media screen {
      display: flex;
      flex-direction: column;
    }
  }

  .modal-backdrop.show + .modal-backdrop.show {
    opacity: 0;
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  .btn.btn-link:hover, .btn.btn-link:focus {
    text-decoration: none;
  }

  a:not(:hover), .btn-link:not(:hover) {
    text-decoration: none !important;
  }

  a[download]:after {
    ${iconStyles};
    margin-left: 2px;
    content: "\\eb01";
    /* content: "\f08e"; */
  }

  img {
    flex: 0 0 auto;
    object-fit: scale-down;
    max-width: 100%;
    height: auto;
  }
  
  samp {
    color: ${(p) => p.theme.grayDark};
    font-size: 1.1em;
  }
  
  .p-sm {
    font-size: 14px;
  }

  .meta-info {
    font-size: 0.75rem;
  }
 
  .btn-group.btn-link.ss-delete {
    color: ${(p) => p.theme.brandDanger}
  }

  .modal-backdrop.modal-backdrop-clear.in {
    opacity: 0;
  }

  ul.narrow, .narrow ul {
    padding-inline-start: 20px;
    padding-left: 20px;
  }

  th.narrow, td.narrow {
    width: 10px;
  }

  .avatar-image {
    border-radius: 50%;
    width: ${(p) => p.theme.avatarSizeBase};
    height: ${(p) => p.theme.avatarSizeBase};
    &.size-small {
      width: ${(p) => p.theme.avatarSizeSmall};
      height: ${(p) => p.theme.avatarSizeSmall};
    }
  }

  .avatar-text:not(:first-child) {
    padding-left: 10px;
  }

  h2.icon, h3.icon, h4.icon {
    display: flex;
    & > i {
      flex: 0 0 24px;
      font-size: 18px;
      color: ${(p) => p.theme.grayLight};
    }
  }
  
  .ms-6 {
    margin-left: 24px;
  }

  .ss-twitter {
    color: #1da1f2;
  }

  .ss-facebook,
  .ss-facebook-square {
    color: #3b5998;
  }

  .label-required::after {
    content: '*';
  }
  
  .input-height {
    height: 34px;
  }
  
  .max-width-100 {
    max-width: 100%;
  }

  // Override default primary color
  .text-primary.text-primary {
    color: ${(p) => p.theme.brandPrimary} !important;
  }
  .border-primary.border-primary {
    border-color: ${(p) => p.theme.brandPrimary} !important;
  }

  .text-primary-dark,
  .shaded.text-primary {
    color: ${(p) => p.theme.textPrimary} !important;
  }

  .help-block, .form-text {
    color: ${(props) => props.theme.grayLight};
    font-size: 0.875rem;
    @media (max-width: ${(p) => p.theme.screenSmMax}) {
      font-size: 0.75rem;
    }
  }

  .text-gray {
    color: ${(p) => p.theme.gray} !important;
  }

  .bg-gray {
    background-color: ${(p) => p.theme.grayLighter} !important;
  }

  .bg-primary {
    background-color: ${(props) => props.theme.brandPrimary} !important;
    color: ${(props) => props.theme.bodyBg};
  }
  
  .w-75 {
    width: 75%;
    margin: auto;
  }

  select.form-control {
    border-radius: 16px;
    width: auto;
  }

  textarea.form-control {
    border-radius: 16px;
  }
  
  .form-control.form-control::-moz-placeholder {
    ${placeholderMixin};
  }
  .form-control.form-control:-ms-input-placeholder {
    ${placeholderMixin};
  }
  .form-control.form-control::-webkit-input-placeholder {
    ${placeholderMixin};
  }
  
  input[type="date"].form-control,
  input[type="time"].form-control {
    max-width: 12em;
  }

  select.input-placeholder, option.input-placeholder {
    ${placeholderMixin};
    & > option:not(.input-placeholder) {
      font-style: normal;
      color: inherit;
    }
  }

  .opaque {
    opacity: 0.5 !important;
  }

  .opacity-1 > .form-check-input {
    opacity: 1 !important;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .sticky {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }

  @media not print {
    .shaded {
      background-color: ${(props) => props.theme.backgroundImage ? 'transparent' : props.theme.contentBg};
      .card {
        border: none;
        .card {
          border: var(--bs-card-border-width) solid var(--bs-card-border-color);
        }
      }
    }

    .shaded-light {
      background-color: ${(props) => props.theme.backgroundImage ? 'transparent' : props.theme.contentBgLight};
    }
  }

  label.control-label {
    font-size: 12px;
    color: ${(props) => props.theme.gray};
  }

  .intro-text {
    color: ${(props) => props.theme.gray};
  }

  .btn-link::not(.text-muted) .ss-trash {
    color: ${(p) => p.theme.brandDanger};
  }

  .bs-open {
    background-color: ${(p) => p.theme.contentBg};
    color: ${(p) => p.theme.brandPrimary};
    border-color: transparent;
    box-shadow: none;
  }

  .list-group-tree {
    .list-group-item:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &.px-0 .list-group-item {
      padding-left: 0;
      padding-right: 0; 
    }
  }

  .list-group-item {
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
  }

  .list-disc {
    list-style-type: disc;
  }
  
  .max-width-lg {
    max-width: 908px;
    margin: auto;
  }

  .max-width-md {
    max-width: 640px;
    width: calc(100vw - 20px);
    margin: auto;
  }

  .max-width-sm {
    max-width: 480px;
    width: calc(100vw - 20px);
    margin: auto;
  }

  .max-width-xs {
    max-width: 390px;
    width: calc(100vw - 20px);
    margin: auto;
  }

  .icon-lower i,
  i.icon-lower {
    transform: translateY(12%);
    &.ss-view {
      transform: translateY(24%);
    }
  }

  .lower-2 {
    transform: translateY(0.3rem);
  }

  .lower-1 {
    transform: translateY(0.15rem);
  }

  .higher-2 {
    transform: translateY(-0.3rem);
  }

  .higher-1 {
    transform: translateY(-0.15rem);
  }

  .text-info {
    color: ${(p) => p.theme.textInfo} !important;
  }

  .text-success {
    color: ${(p) => p.theme.textSuccess} !important;
  }

  .text-warning {
    color: ${(p) => p.theme.textWarning} !important;
  }

  .btn-outline-warning:not(:hover):not(:focus) {
    color: ${(p) => p.theme.textWarning} !important;
    border-color: ${(p) => p.theme.textWarning} !important;
  }

  .user-is-tabbing .btn-outline-warning:focus {
    color: var()(--bs-btn-hover-color) !important;
  }

  .text-danger {
    color: ${(p) => p.theme.textDanger} !important;
  }

  .input-border {
    ${inputMixin};
    &[disabled] {
      background-color: #eee;
    }
  }

  .table.no-border > * > tr > * {
    border: none;
  }

  .with-colon {
    :after {
      content: ': ';
    }
  }

  .within-parentheses {
    :before {
      content: '(';
    }
    :after {
      content: ')';
    }
  }

  .emoji-popover {
    width: 352px;
    max-width: 100%;
  }

  .popover-lg {
    max-width: 100vw;
    @media (min-width: 768px) {
      max-width: 640px;
    }
  }

  .bold-link a {
    font-weight: bold;
  }

  .with-debug-menu #userlane-assistant-container {
    margin-right: 240px;
  }

  .open-answer {
    border: 1px solid ${(props) => props.theme.inputBorder};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    padding: 5px 10px;
  }

  .checked-bold {
    input[checked] + span {
      font-weight: bold;
    }
  }
  .rounded-panel {
    border-radius: ${(p) => p.theme.panelBorderRadius} !important;
  }

  .rounded-input {
    border-radius: ${(p) => p.theme.inputBorderRadius} !important;
  }

  @media (min-width: 576px) {
    flex-nowrap-sm {
      flex-wrap: nowrap !important;
    }
  }

  @media (min-width: ${(p) => p.theme.screenSmMin}) {
    .mck-sidebox.fade.in.mck-sidebox.fade.in, 
    .mck-sidebox.mck-fade.in.mck-sidebox.mck-fade.in,
    .mck-sidebox .mck-box-backdrop.in.mck-box-backdrop.in,
    .mck-box .mck-box-sm.mck-box-sm {
      width: 420px;
    }
    .text-sm-right {
      text-align: right !important;
    }
  }

  .font-size-1 {
    font-size: 14px !important;
  }

  @media not print {
    .visible-print {
      display: none !important;
    }
  }

  .modal-header {
    align-items: flex-start;
    & > .btn-close {
      margin-top: 0;
    }
  }
  
  .scroll-sentry {
    height: 1px;
  }

  @media print {
    html {
      width: 100%;
    }
    body.modal-open {
      height: auto;
      overflow: initial !important;
      & > :not([role=dialog]) {
        display: none;
      }
    }
    .btn.btn:not(.btn-link), .btn-group.btn-group, .close.close {
      display: none;
    }
    a[href].no-print-href:after {
      content: none !important;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .no-print-max-height {
      max-height: none !important;
    }
    .print-border-bottom {
      border-bottom: 1px solid #dee2e6 !important;
    }
    body.modal-open {
      height: auto;
      overflow: initial !important;
      & > :not([role=dialog]) {
        display: none;
      }
    }
  }
`;

export default GlobalStyle;
